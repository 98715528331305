import React from "react";
import { Button, Form, FormTextField } from "@periplus/ui-library";
import { Formik } from "formik";
import * as Yup from "yup";
import Title from "../../components/FormTitle";
import FormField from "../../components/FormField";
import FormFieldLabel from "../../components/FormFieldLabel";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth/AuthContext";

export default () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const initialValues = { otpCode: "" };

  const validationSchema = Yup.object().shape({
    otpCode: Yup.string().trim().required().max(6).min(6),
  });

  const handleSubmit = (values: typeof initialValues) => {
    const castedValues = validationSchema.cast(values) as typeof initialValues;
    auth.twoFactorAuth(castedValues.otpCode);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 3,
          }}
        >
          <Title>{t("OTP Code")}</Title>
          <FormField>
            <FormFieldLabel>{t("Otp Code")}</FormFieldLabel>
            <FormTextField
              name="otpCode"
              fullWidth
              InputProps={{
                placeholder: t("Enter Otp Code"),
              }}
            />
          </FormField>
          <Button
            variant="primary"
            onClick={submitForm}
            sx={{
              alignSelf: "center",
              minWidth: "100px",
            }}
          >
            {t("Submit")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
