import { Typography, styled } from "@periplus/ui-library";

export default styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#4C496F",
  })
);
