import gql from "graphql-tag";
import { useDebounceCallback } from "@react-hook/debounce";
import { useUpdateCurrentUserUiSettingsMutation } from "graphql/generated";

gql`
  mutation updateCurrentUserUiSettings($id: uuid!, $ui_settings: jsonb!) {
    update_user_pp_by_pk(
      pk_columns: { id: $id }
      _append: { ui_settings: $ui_settings }
    ) {
      id
    }
  }
`;

export default () => {
  const [mutation, result] = useUpdateCurrentUserUiSettingsMutation();

  const debouncedMuttation = useDebounceCallback(mutation, 10000);

  return [debouncedMuttation, result] as const;
};
