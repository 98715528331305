import { Typography, styled } from "@periplus/ui-library";

export default styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    alignSelf: "start",
    fontSize: "60px",
    lineHeight: "72px",
    fontWeight: 600,
    letterSpacing: "-0.025em",
    color: "#1D1D4B",
  })
);
