import React from "react";
import { Typography, Form, Link, Alert } from "@periplus/ui-library";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useAuth } from "contexts/Auth/AuthContext";
import { useMsal } from "@azure/msal-react";
import FormButton from "../../components/FormButton";
import Title from "../../components/FormTitle";
import FormField from "../../components/FormField";
import FormFieldLabel from "../../components/FormFieldLabel";
import FormPasswordTextField from "../../components/FormPasswordTextField";
import FormTextField from "../../components/FormTextField";
import { useHistory } from "react-router-dom";

interface Props {
  onLogin: (is2FaEnabled: boolean) => void;
}

export default ({ onLogin }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const sessionExpired = JSON.parse(
    localStorage.getItem("sessionExpired") ?? "false"
  );
  const auth = useAuth();
  const { instance } = useMsal();

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .required(t("Username is a required field"))
      .email(t("Username must be a valid email")),
    password: Yup.string().trim().required(t("Password is a required field")),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    const castedValues = validationSchema.cast(values) as typeof initialValues;
    const is2FaEnabled = await auth.login(
      castedValues.username,
      castedValues.password
    );
    onLogin(is2FaEnabled);
  };

  const handleMsalLogin = async () => {
    const res = await instance.loginPopup({
      scopes: [],
      prompt: "select_account",
    });
    auth.microsoftLogin(res.idToken);
  };

  return (
    <>
      {sessionExpired && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {t("Your session has expired. Please log in again.")}
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Form
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 3,
            }}
          >
            <Title>{t("Login")}</Title>
            <FormField>
              <FormFieldLabel>{t("Email")}</FormFieldLabel>
              <FormTextField
                autoComplete="username"
                name="username"
                variant="outlined"
                fullWidth
                InputProps={{
                  placeholder: t("Enter email address"),
                }}
              />
            </FormField>
            <FormField>
              <FormFieldLabel>{t("Password")}</FormFieldLabel>
              <FormPasswordTextField
                autoComplete="current-password"
                id="password"
                name="password"
                variant="outlined"
                fullWidth
                InputProps={{
                  placeholder: t("Enter password"),
                }}
              />
              <Link
                underline="none"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/forgot-password");
                }}
                sx={{
                  alignSelf: "end",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "21px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#797693",
                }}
              >
                {t("Forgot Password?")}
              </Link>
            </FormField>
            <FormButton
              variant="primary"
              onClick={submitForm}
              sx={{
                alignSelf: "center",
                minWidth: "100px",
              }}
            >
              {t("Login")}
            </FormButton>
          </Form>
        )}
      </Formik>
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 2,
          color: "#A5A4B7",
          "&:before,:after": {
            content: '""',
            flex: "1 1",
            borderBottom: "1px solid #E9E8ED",
            margin: "auto",
          },
        }}
      >
        {t("or")}
      </Typography>
      <FormButton variant="secondary" onClick={handleMsalLogin}>
        {t("Microsoft Office 365")}
      </FormButton>
    </>
  );
};
