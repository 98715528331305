import React, { useState } from "react";
import TwoFactorForm from "./TwoFactorForm";
import LoginForm from "./LoginForm";

interface Props {}

export default ({}: Props) => {
  const [isTwoFactorStep, setIsTwoFactorStep] = useState(false);

  const handleLogin = (is2FaEnabled: boolean) => {
    setIsTwoFactorStep(is2FaEnabled);
  };

  if (isTwoFactorStep) return <TwoFactorForm />;

  return <LoginForm onLogin={handleLogin} />;
};
