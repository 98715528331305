import { PropsWithChildren } from "react";
import {
  Box,
  Theme,
  createTheme,
  ThemeProvider,
  ThemeOptions,
  ToggleButtonGroup,
  ToggleButton,
} from "@periplus/ui-library";
import square from "./images/square.svg";
import { useTranslation } from "react-i18next";
import { changeAppLanguage } from "i18n";

export default ({ children }: PropsWithChildren) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme(theme, {
          typography: {
            body1: {
              fontFamily: "Plus Jakarta Sans",
            },
            h3: {
              fontFamily: "Plus Jakarta Sans",
            },
          },
          components: {
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  borderRadius: "999px",
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  height: "48px",
                  borderRadius: "999px",
                  "& *": {
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "0em",
                  },
                },
                containedPrimary: {
                  background: "#7C4DE1",
                  "&:hover": {
                    background: "#7C4DE1",
                  },
                },
                outlinedPrimary: {
                  borderColor: "#E9E8ED",
                  color: "#332E81",
                  "&:hover": {
                    borderColor: "#7C4DE1",
                  },
                },
              },
            },
            MuiToggleButton: {
              styleOverrides: {
                root: ({ theme }) => ({
                  ...theme.typography.body1,
                  width: "60px",
                  height: "48px",
                  borderRadius: "999px",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "21px",
                  letterSpacing: "0em",
                  borderColor: "#E9E8ED",
                  color: "#332E81",
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#7C4DE1",
                    "&:hover": {
                      backgroundColor: "#7C4DE1",
                    },
                  },
                }),
              },
            },
          },
        } as ThemeOptions)
      }
    >
      <Box
        sx={(theme) => ({
          position: "relative",
          boxSizing: "border-box",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(./${process.env.REACT_APP_NAME}/logo.svg), url(${square})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom 64px right 60px, center left",
          backgroundSize: "auto auto, auto 100%",
          [theme.breakpoints.up("md")]: { justifyContent: "start" },
        })}
      >
        <ToggleButtonGroup
          value={language}
          onChange={(e, value) => changeAppLanguage(value)}
          exclusive
          sx={(theme) => ({
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
            color: "#332E81",
          })}
        >
          <ToggleButton value="de" sx={{ ...(language !== "de" && { backgroundColor: "white" }) }}>DE</ToggleButton>
          <ToggleButton value="en" sx={{ ...(language !== "en" && { backgroundColor: "white" }) }}>EN</ToggleButton>
        </ToggleButtonGroup>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: 3,
            alignItems: "center",
            width: "295px",
            my: 5,
            [theme.breakpoints.up("md")]: {
              ml: "50%",
              mr: 4,
              width: "395px",
            },
          })}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
