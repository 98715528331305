import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

export default () => {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route component={() => <Redirect to="/login" />} />
    </Switch>
  );
};
