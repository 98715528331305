import { gql } from "@apollo/client";
import {
  GetCurrentUserQuery,
  useGetCurrentUserLazyQuery,
} from "graphql/generated";
import { NotNullable } from "utils/utilityTypes";

type CurrentUserEntityOriginal = NonNullable<GetCurrentUserQuery["user"]>;
export type CurrentUserEntity = NotNullable<
  CurrentUserEntityOriginal,
  "firstName" | "lastName" | "email"
>;

gql`
  query getCurrentUser {
    user: getCurrentUser {
      permissionsDesk
      userId: id
      firstName
      lastName
      email
      phoneNumber
      uiSettings
      uiSettingsTemplates
      externalAuthProvider
      tenantId
      tenant {
        id
        tenant_name: tenantName
        permissions_js: permissionsJs
        tenantDocumentTypes: documentTypeTenantSettings {
          document_type: documentType
          default_flags: defaultFlags
          meta
          code
        }
        declaration_labels: declarationLabels {
          id
          title
          description
          color
          is_deleted: isDeleted
        }
        address {
          id
          country
          city
          street
          street_number: streetNumber
          zipcode
          address_type: addressTypeId
          company_name: companyName
        }
      }
      modules {
        name
      }
    }
  }
`;

export default useGetCurrentUserLazyQuery;
