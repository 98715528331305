import React, { lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AuthProvider } from "contexts/Auth/AuthContext";
import BaseRoutes from "./Routes";
import { ApolloProvider } from "@apollo/client";
import client from "graphql/client";
import { ApplicationProvider } from "contexts/ApplicationContext";
import { useTranslation } from "react-i18next";
import { UiLibraryProvider, theme, CssBaseline } from "@periplus/ui-library";
import { createTheme, ThemeProvider } from "@mui/material";
import { LanguageType } from "../i18n";
import { SnackbarProvider } from "contexts/SnackbarContext";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../msalAuthConfig";
import ErrorBoundary from "./ErrorBoundary";
import { Helmet } from "react-helmet";
import { enUS, deDE } from "@mui/material/locale";

const Portal = lazy(() => import(`portals/${process.env.REACT_APP_NAME}`));

const App: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const localizedTheme = createTheme(theme, { en: enUS, de: deDE }[language]!);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="shortcut icon"
          href={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NAME}/favicon.ico`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link
          rel="manifest"
          href={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_NAME}/manifest.json`}
        />
        <title>
          {
            { adit: "Adit", liber: "WeDeclare | Die Zukunft der Verzollung" }[
              process.env.REACT_APP_NAME as "adit" | "liber"
            ]
          }
        </title>
      </Helmet>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <ApolloProvider client={client}>
            <SnackbarProvider>
              <AuthProvider>
                <ApplicationProvider>
                  <ThemeProvider theme={localizedTheme}>
                    <CssBaseline />
                    <UiLibraryProvider language={language as LanguageType}>
                      <ErrorBoundary>
                        <BaseRoutes>
                          <Portal />
                        </BaseRoutes>
                      </ErrorBoundary>
                    </UiLibraryProvider>
                  </ThemeProvider>
                </ApplicationProvider>
              </AuthProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </MsalProvider>
      </BrowserRouter>
    </>
  );
};

export default Sentry.withProfiler(App);
