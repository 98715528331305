//import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || "",
    authority: process.env.REACT_APP_MICROSOFT_AUTHORITY || "",
    redirectUri: window.location.origin || "",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        //   default:
        //     return;
        // }
      },
    },
  },
});

export const loginRequest = {};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v2.0/me",
};
