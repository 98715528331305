import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "locales/en-US.json";
import de from "locales/de-CH.json";
import dayjs from "dayjs";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import weekOfYear from "dayjs/plugin/weekOfYear";
import config from "config";

dayjs.extend(weekOfYear);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

export type LanguageType = "en" | "de";

export const FALL_BACK_LANGUAGE = "en";

// Language detector options
const options = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
};

// the translations
const resources = {
  en,
  de,
};

let i18nLng = (localStorage.getItem("i18nextLng") || "de").split("-")[0];
if (!config.usedLanguages.includes(i18nLng as LanguageType)) {
  i18nLng = "de";
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: i18nLng,
    fallbackLng: "en",
    ns: ["common"], // Here we can define namespaces to group/order the translations into modules. Would support lazy loading at a later stage..
    defaultNS: "common",
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: ":",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: options, //
  });

dayjs.locale(i18n.language);

export const changeAppLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
  dayjs.locale(lng);
};

export default i18n;
