import React from "react";
import { useAuth } from "contexts/Auth/AuthContext";
import NonAuthenticatedRoutes from "./NonAuthenticatedRoutes";
import NonAuthorizedRoutes from "./NonAuthorizedRoutes";
import Layout from "./components/Layout";

export default () => {
  const { isAuthenticated } = useAuth();

  return (
    <Layout>
      {!isAuthenticated ? <NonAuthenticatedRoutes /> : <NonAuthorizedRoutes />}
    </Layout>
  );
};
