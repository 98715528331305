import * as firebase from "firebase/app";
import "firebase/analytics";

import { noop } from "lodash";

let app = {};
let analytics = {
  setUserProperties: noop,
  setUserId: noop,
  logEvent: noop,
};

if (
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.react_app_environment === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "prod" ||
  process.env.react_app_environment === "prod"
) {
  const firebaseConfig = {
    apiKey: "AIzaSyA7V8xeuIiX-7-W2i_G-NGlTySYfkTywpQ",
    authDomain: "adit-7d709.firebaseapp.com",
    databaseURL: "https://adit-7d709.firebaseio.com",
    projectId: "adit-7d709",
    storageBucket: "adit-7d709.appspot.com",
    messagingSenderId: "957470210212",
    appId: "1:957470210212:web:d3968ed96d668614698e31",
    measurementId: "G-SD2R6XTGKJ",
  };

  app = firebase.initializeApp(firebaseConfig);
  analytics = firebase.analytics();
}

const useFirebaseAnalytics = () => {
  // process.env.NODE_ENV === "development"

  return {
    firebase: app,
    analytics,
  };
};

export default useFirebaseAnalytics;
