import { gql } from "@apollo/client";
import { useGetCurrentUserTenantConfigLazyQuery } from "graphql/generated";

gql`
  query getCurrentUserTenantConfig($tenantId: ID!) {
    getTenantConfig(tenantId: $tenantId)
  }
`;

export default useGetCurrentUserTenantConfigLazyQuery;
