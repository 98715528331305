import React from "react";
import { Form } from "@periplus/ui-library";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormTitle from "../components/FormTitle";
import FormField from "../components/FormField";
import FormFieldLabel from "../components/FormFieldLabel";
import { useHistory } from "react-router-dom";
import axios from "axios";
import getDynamicBaseUrl from "utils/urlHelper";
import FormButton from "../components/FormButton";
import FormTextField from "../components/FormTextField";

interface Props { }

export default ({ }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  const initialValues = { email: "" };

  const validationSchema = Yup.object({
    email: Yup.string().trim().required().email(),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    const castedValues = validationSchema.cast(values) as typeof initialValues;
    try {
      await axios.post(
        `${getDynamicBaseUrl()}/auth/forgot-password`,
        {
          email: castedValues.email,
          client: process.env.REACT_APP_NAME,
        },
        { withCredentials: false }
      );
      enqueueSnackbar(t("Password reset link sent"), {
        variant: "success",
      });
    } catch (err: any) {
      enqueueSnackbar(t(err.message), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Form
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 3,
            }}
          >
            <FormTitle>{t("Password reset")}</FormTitle>
            <FormField>
              <FormFieldLabel>{t("Email")}</FormFieldLabel>
              <FormTextField
                name={`email`}
                variant="outlined"
                fullWidth
                type="email"
                InputProps={{
                  placeholder: t("Enter email address"),
                }}
              />
            </FormField>
            <FormButton
              variant="primary"
              onClick={submitForm}
              sx={{
                alignSelf: "center",
                minWidth: "100px",
              }}
            >
              {t("Submit")}
            </FormButton>
          </Form>
        )}
      </Formik>
      <FormButton
        variant="secondary"
        onClick={() => {
          history.push("/login");
        }}
        sx={{
          minWidth: "100px",
        }}
      >
        {t("Login")}
      </FormButton>
    </>
  );
};
